import React, { Component, useEffect, useState } from "react";
import "./landing.css";
import CustomerForm from "../customerForm/CustomerForm";
import Loading from "../loading/Loading";
import { Card, message } from "antd";
import InstructionPage from "../welcomepage/InstructionPage";
import LandingContent from "./LandingContent";

import { postRequest, getRequest } from "../utils/APIUtility";
import { AUTH_SERVICE } from "../utils/APIConfig";

const LandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState("landing");
  const [onboardingDetails, setOnboardingDetails] = useState();

  useEffect(() => {
    getOnboardingDetails();
  }, []);

  const getOnboardingDetails = () => {
    let onboardingID =
      window.location.pathname.toString().split("/")[1] ||
      "647f50ddc2efad64544e2d76";
    console.log("onboardingID ==>", onboardingID);
    let url = `${AUTH_SERVICE}api/onboarding/${onboardingID}`;
    getRequest(url).then((res) => {
      setLoading(false);
      setOnboardingDetails(res);
      if (res && res.status == "RGCA") {
        setPage("customer");
      } else if (res && res.status == "RGAA") {
        setPage("completed");
      } else if (res && res.status == "RGEX") {
        setPage("expired");
      }
    });
  };

  const handlePage = (pageName) => {
    if (onboardingDetails) {
      setPage(pageName);
    } else {
      message.error("Please check the link.");
    }
  };
  return (
    <div className="main-container mobile-card">
      {loading && <Loading />}

      {page == "landing" ? (
        <div className="container">
          <LandingContent handlePage={handlePage} />
        </div>
      ) : page == "expired" ? (
        <div className="container">
          <Card hoverable>
            <InstructionPage handlePage={handlePage} status={"expired"} />
          </Card>
        </div>
      ) : page == "completed" ? (
        <div className="container">
          <Card hoverable>
            <InstructionPage handlePage={handlePage} status={"completed"} />
          </Card>
        </div>
      ) : (
        <div className="container">
          <CustomerForm handlePage={handlePage} />
        </div>
      )}
    </div>
  );
};

export default LandingPage;
