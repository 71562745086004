import React, { Component, useEffect, useState } from "react";
import "./landing.css";
import CustomerForm from "../customerForm/CustomerForm";
import Loading from "../loading/Loading";
import { Card } from "antd";
import BackgroundImage1 from "../../assets/images/landing/ai-contact-center-solution-1-768x798.png";
import BackgroundImage2 from "../../assets/images/landing/dialogview-screen-1-scaled.jpg";
import BackgroundImage3 from "../../assets/images/landing/dialogview-conferencing-1-1.png";
import DialogViewLogo from "../../assets/images/logo2.png";

import ImageWithDescription from "./ImageWithDescription";

const LandingContent = ({ handlePage }) => {
  return (
    <Card hoverable className="landing-info-card">
      <div className="lc-main-div">
        <ImageWithDescription
          logo={DialogViewLogo}
          imageSrc={BackgroundImage1}
          mainTitle={
            "Streamline Your Communications, Elevate Your Customer Experience"
          }
          description={
            "Experience seamless connection and collaboration with our intelligent Contact Center and Conferencing Solutions."
          }
          buttonText={"Get Started"}
          handlePage={handlePage}
        />
        <ImageWithDescription
          keepImageRight={false}
          imageSrc={BackgroundImage2}
          mainTitle={
            "Elevate your Customer Experience with our Contact Center Solution"
          }
          subTitle={""}
          description={`
            Our Contact Center Solution seamlessly integrates all your communication channels, ensuring
efficient management and improved customer satisfaction. Additionally, our Conferencing
Solution revolutionizes remote collaboration, ensuring your meetings run smoothly, no matter
where your team is located.
          `}
          handlePage={handlePage}
        />
        <ImageWithDescription
          imageSrc={BackgroundImage3}
          mainTitle={"Introducing, Dialogview's Conferencing Solution"}
          subTitle={""}
          description={`
        Dialogview's Conferencing Solution offers a seamless, easy-to-use platform that enhances team collaboration, 
        streamlines meetings and drives innovation.
          `}
          handlePage={handlePage}
        />
      </div>
    </Card>
  );
};

export default LandingContent;
