import React, { Component } from "react";
import "./welcome.css";
import { Row, Col, Button } from "antd";
import { FiArrowRight } from "react-icons/fi";
const InstructionPage = ({ handlePage, status, email }) => {
  return (
    <div className="welcome-container mobile-card">
      {status == "expired" ? (
        <Row className="card-content">
          <Col className="link-expired-card">
            <h1 className="sticky-top">Link Got Expired</h1>
            <h2>Please contact your Admin for further details.</h2>
          </Col>
        </Row>
      ) : (
        <Row className="card-content">
          <Col className="thank-you-card">
            <h1 className="sticky-top">Your registration has been completed!</h1>
            <h2>Please click on the link sent to email to reset the password.</h2>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InstructionPage;
