import {
  PlusOutlined,
  UploadOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Modal, Progress, Upload, message, Button, Image } from "antd";
import { useState, useEffect, useRef } from "react";
import "./utils.css";

const AntdUpload = ({ currentImage, handleImage, propertyName }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [fileList, setFileList] = useState([]);
  const [fromUrl, setFromUrl] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const FileUploadRef = useRef(null);

  useEffect(() => {
    console.log("currentImage -->", currentImage);
    if (fromUrl && currentImage && currentImage.toString().includes("https")) {
      addImageFromURL(currentImage);
      setShowEdit(true);
    }
  }, []);

  const addImageFromURL = (imageUrl) => {
    console.log("adding image from url =>", imageUrl);
    const fileObj = {
      uid: new Date().getTime(), // Assign a unique ID to the file object
      name: "Image",
      status: "done",
      url: imageUrl,
    };
    setFileList([fileObj]);
  };

  const handleUpload = (info) => {
    // Handle the upload logic here
    setShowEdit(true);
    console.log(info.file);
    handleImage("logo", info.file);
    setFileList(info.fileList);
    setLoading(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const handleCancel = () => setPreviewVisible(false);
  const handleAction = (e) => handleImage(propertyName, e);

  const handleCompanyLogo = (value) => {
    setLoading(true);
    console.log("uploaded image ->", value);

    // add image to antd upload list
    const fileObj = {
      uid: new Date().getTime(), // Assign a unique ID to the file object
      name: "Image",
      status: "done",
      url: URL.createObjectURL(value),
    };
    setFileList([fileObj]);
    handleImage("logo", value);
    setLoading(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  return (
    <>
      {showEdit && (
        <div
          className="custom-upload-thumbnail-overlay"
          style={{
            position: "relative",
            float: "right",
            top: "20px",
            left: "-15px",
            zIndex: "99",
          }}
        >
          {loading ? (
            antIcon
          ) : (
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                if (FileUploadRef && FileUploadRef.current) {
                  FileUploadRef.current.click();
                }
              }}
            />
          )}
        </div>
      )}
      <Upload
        className="custom-upload"
        listType="picture-circle"
        fileList={fileList}
        beforeUpload={() => false}
        onChange={handleUpload}
        onPreview={handlePreview}
        action={handleAction}
        showUploadList={{ showRemoveIcon: false }}
      >
        {fileList.length >= 1 ? null : <Button icon={<UploadOutlined />} />}
      </Upload>

      <input
        id="image-upload-input"
        type="file"
        onChange={(e) => handleCompanyLogo(e.target.files[0])}
        ref={FileUploadRef}
        style={{ display: "none" }}
      />
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        {previewImage ? (
          <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
        ) : null}
      </Modal>
    </>
  );
};
export default AntdUpload;
