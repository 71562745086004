import React from "react";
import "./imageWithDescription.css";

const ImageWithDescription = ({
  logo,
  imageSrc,
  mainTitle,
  subTitle,
  description,
  buttonText,
  handlePage,
  keepImageRight = true,
}) => {
  return keepImageRight ? (
    <div className="component-container">
      <div className="content-container">
        {logo && (
          <div className="logo-container">
            <img src={logo} alt="Image" className="logo" />
          </div>
        )}
        <h2 className="main-title">{mainTitle}</h2>
        <h3 className="sub-title">{subTitle}</h3>
        <p className="description">{description}</p>
        <div className="button-container">
          {buttonText && (
            <button
              className="button"
              onClick={() => handlePage("customerForm")}
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
      <div className="image-container">
        <img src={imageSrc} alt="Image" className="image" />
      </div>
    </div>
  ) : (
    <div className="component-container">
      <div className="image-container">
        <img src={imageSrc} alt="Image" className="image" />
      </div>
      <div className="content-container">
        <h2 className="main-title">{mainTitle}</h2>
        <h3 className="sub-title">{subTitle}</h3>
        <p className="description">{description}</p>
        {buttonText && (
          <button className="button" onClick={() => handlePage("customerForm")}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageWithDescription;
