export const clientUrl = () => {
  // return "dialogview-dev-demo.dialogview.ai";
  try {
    let domainName = "";
    if (window.location.ancestorOrigins.length === 1) {
      domainName = window.location.ancestorOrigins[0].replace(
        /(^\w+:|^)\/\//,
        ""
      );
    } else if (window.location.ancestorOrigins.length === 0) {
      domainName = window.location.host;
    }
    console.log("Domain name: ", domainName);
    return domainName;
  } catch (error) {
    let url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href;
    console.log("URL from parent: ", url);
    let domain = new URL(url);
    console.log("URL Obj ->", domain);
    return domain.hostname ? domain.hostname : "";
  }
};

export const getRequest = (URL, customHeaders = {}) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "Json",
        ...customHeaders,
      },
    };
    return fetch(URL, requestOptions)
      .then(async (response) => {
        const startRes = await response.json();
        const data = startRes.data;
        console.log("/GET RES =>", data);
        return data;
      })
      .catch((error) => {
        console.log("API-ERROR: " + error.message);
        return error;
      });
  } catch (error) {
    console.log("POST-CATCH-BLOCK::", error);
    return error;
  }
};

export const postRequest = (URL, bodyContent = {}, customHeaders = {}) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "Json",
        ...customHeaders,
      },
      body: JSON.stringify(bodyContent),
    };
    return fetch(URL, requestOptions)
      .then(async (response) => {
        const startRes = await response.json();
        const data = startRes.data;
        console.log("/POST res =>", data);
        return data;
      })
      .catch((error) => {
        console.log("API-ERROR: " + error.message);
        return error;
      });
  } catch (error) {
    console.log("POST-CATCH-BLOCK::", error);
    return error;
  }
};

export const putRequest = (URL, bodyContent = {}, customHeaders = {}) => {
  try {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "Json",
        ...customHeaders,
      },
      body: JSON.stringify(bodyContent),
    };
    return fetch(URL, requestOptions)
      .then(async (response) => {
        const startRes = await response.json();
        const data = startRes.data;
        console.log("/POST res =>", data);
        return data;
      })
      .catch((error) => {
        console.log("API-ERROR: " + error.message);
        return error;
      });
  } catch (error) {
    console.log("POST-CATCH-BLOCK::", error);
    return error;
  }
};

export const patchRequest = (URL, bodyContent = {}, customHeaders = {}) => {
  try {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "Json",
        ...customHeaders,
      },
      body: JSON.stringify(bodyContent),
    };
    return fetch(URL, requestOptions)
      .then(async (response) => {
        const startRes = await response.json();
        const data = startRes.data;
        console.log("/POST res =>", data);
        return data;
      })
      .catch((error) => {
        console.log("API-ERROR: " + error.message);
        return error;
      });
  } catch (error) {
    console.log("POST-CATCH-BLOCK::", error);
    return error;
  }
};

export const postFormRequest = (URL, bodyContent, customHeaders = {}) => {
  try {
    const requestOptions = {
      method: "POST",
      body: bodyContent,
    };
    return fetch(URL, requestOptions)
      .then(async (response) => {
        const startRes = await response.json();
        const data = startRes.data;
        console.log("/POST res =>", data);
        return data;
      })
      .catch((error) => {
        console.log("API-ERROR: " + error.message);
        return error;
      });
  } catch (error) {
    console.log("POST-CATCH-BLOCK::", error);
    return error;
  }
};

export const addToLocalStorage = (key, value, secret_key) => {
  if (secret_key) {
    let storageCopy = JSON.parse(
      localStorage.getItem(`dialogView_${secret_key}`)
    );
    storageCopy[key] = value;
    localStorage.setItem(
      `dialogView_${secret_key}`,
      JSON.stringify(storageCopy)
    );
  }
};

export const getLocalStorageData = (secret_key) => {
  return JSON.parse(localStorage.getItem(`dialogView_${secret_key}`));
};
