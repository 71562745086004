import React, { Component, useEffect, useState } from "react";
import "./customerForm.css";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  message,
  Card,
  Modal,
  Steps,
} from "antd";
import {
  CheckOutlined,
  ShopOutlined,
  OrderedListOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { FiCheckCircle, FiCheckSquare, FiCheck } from "react-icons/fi";
import { autoPlay } from "react-swipeable-views-utils";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";
import { BiRightArrowAlt, BiLeftArrowAlt } from "react-icons/bi";
import AntdUpload from "../utils/AntdUpload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CallCenterGif from "../../assets/icons/call-center.gif";
import VideoCallGif from "../../assets/icons/facetime.gif";
import DialogViewLogo from "../../assets/images/logo2.png";

import {
  postRequest,
  getRequest,
  postFormRequest,
  patchRequest,
  putRequest,
  clientUrl,
} from "../utils/APIUtility";
import { AUTH_SERVICE, BASE_URL } from "../utils/APIConfig";
import Loading from "../loading/Loading";

// CUSTOMER FORM
const CustomerForm = ({ handlePage }) => {
  const [form] = Form.useForm();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState([false, false]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [buttonText1, setButtonText1] = useState("Subscribe ");
  const [buttonText2, setButtonText2] = useState("Subscribe ");
  const [companyLogo, setCompanyLogo] = useState();
  const [onboardingDetails, setOnboardingDetails] = useState({});
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({});

  const steps = ["Company Details", "Subscription Details", "Admin Details"];

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onFinish = () => {
    message.success("Submit success!");
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const isStepFailed = (step) => {
    return step === 11;
  };

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;

  useEffect(() => {
    getOnboardingDetails();
  }, []);

  const getOnboardingDetails = (callback) => {
    let onboardingID = window.location.pathname.toString().split("/")[1] || "";
    console.log("onboardingID ==>", onboardingID);
    let url = `${AUTH_SERVICE}api/onboarding/${onboardingID}`;
    getRequest(url).then((onboardingDetails) => {
      setOnboardingDetails(onboardingDetails);
      if (callback) {
        callback(onboardingDetails);
      }
      setLoading(false);

      // get company details
      getCompanyDetails();

      if (onboardingDetails && onboardingDetails.status == "RGCA") {
        setActiveStep(1);
        updateFormData(onboardingDetails);
      } else if (onboardingDetails && onboardingDetails.status == "RGSA") {
        setActiveStep(2);
      } else if (onboardingDetails && onboardingDetails.status == "RGAA") {
        setActiveStep(2);
        updateFormData(onboardingDetails);
        getAdminDetails(null, onboardingDetails);
      }
    });
  };

  const updateFormData = (res) => {
    // set form values
    form.setFieldsValue({
      company: res.regCompany.company,
      companyEmail: res.regCompany.companyEmail,
      companyWebURL: res.regCompany.companyWebURL,
      companyNumber: res.regCompany.companyNumber,
    });

    // set subscription details
    if (res.regCompany) {
      if (res.regCompany.modules && res.regCompany.modules.length == 1) {
        let subscribedFor = res.regCompany.modules[0];
        subscribedFor == "contact center"
          ? (subscriptionData[0] = true)
          : (subscriptionData[0] = false);
        subscribedFor == "conference"
          ? (subscriptionData[1] = true)
          : (subscriptionData[1] = false);
      }
      if (res.regCompany.modules && res.regCompany.modules.length == 2) {
        let index1 = res.regCompany.modules[0];
        let index2 = res.regCompany.modules[1];
        if (index1 == "contact center" || index2 == "contact center") {
          subscriptionData[0] = true;
        } else {
          subscriptionData[0] = false;
        }
        if (index1 == "conference" || index2 == "conference") {
          subscriptionData[1] = true;
        } else {
          subscriptionData[1] = false;
        }
      }
      setSubscriptionData(subscriptionData);
    }
  };

  const getCompanyDetails = (callback) => {
    let companyId = onboardingDetails?.regCompany?.id;
    if (companyId) {
      setCompanyLogo(false);
      let url = `${AUTH_SERVICE}api/Company/${companyId}`;
      getRequest(url).then((res) => {
        console.log("company details --> ", res);
        if (res && res.companyLogo) {
          setCompanyLogo(res.companyLogo);
        } else {
          setCompanyLogo("new");
        }
        if (callback) {
          callback(res);
        }
      });
    } else {
      setCompanyLogo("new");
      // message.error("Unable to get company details.");
    }
  };

  const handleNextOrBack = (direction = "next") => {
    if (activeStep == 0) {
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      try {
        form
          .validateFields([
            "company",
            "companyEmail",
            "companyWebURL",
            "companyNumber",
          ])
          .then((validateResult) => {
            console.log("validationResult ==>", validateResult);
            if (validateResult && validateResult.errorFields) {
            } else {
              if (!companyLogo || companyLogo == "new") {
                message.error("Please upload company Logo");
              } else {
                handleSaveCompany(validateResult);
              }
            }
          })
          .catch((error) => {
            console.log("validation ERROR :: ", error);
          });
      } catch (errors) {
        console.log("ERROR::", errors);
      }
    } else if (activeStep == 1) {
      handleSaveSubscription();
    } else if (activeStep == 2) {
      try {
        form
          .validateFields(["firstName", "lastName", "email", "contactNumber"])
          .then((validateResult) => {
            console.log("validationResult ==>", validateResult);
            if (validateResult && validateResult.errorFields) {
            } else {
              setConfirmModalData(validateResult);
              showConfirmModal(validateResult);
            }
          })
          .catch((error) => {
            console.log("validation ERROR :: ", error);
          });
      } catch (errors) {
        console.log("ERROR::", errors);
      }
    }

    // scroll to top
    let scrollableContent = document.getElementById("swipe-card-content");
    if (scrollableContent) {
      scrollableContent.scrollTop = 0;
    }
  };

  const handleBack = () => {
    if (activeStep == 1) {
      getCompanyDetails();
    } else if (activeStep == 2) {
      getOnboardingDetails();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // scroll to top
    let scrollableContent = document.getElementById("swipe-card-content");
    if (scrollableContent) {
      scrollableContent.scrollTop = 0;
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const showConfirmModal = (adminDetails) => {
    //prepare data for display

    if (onboardingDetails && onboardingDetails.regCompany) {
      setConfirmModalData({
        ...confirmModalData,
        ...onboardingDetails.regCompany,
        ...adminDetails,
      });
    }
    setConfirmModalVisible(true);
  };

  const handleSaveCompany = (companyDetails) => {
    if (onboardingDetails.status === "RGIT") {
      let onboardingID =
        window.location.pathname.toString().split("/")[1] || "";

      const companyForm = new FormData();
      companyForm.append("logo", companyLogo);
      companyForm.append("company", companyDetails.company);
      companyForm.append("companyEmail", companyDetails.companyEmail);
      companyForm.append(
        "companyNumber",
        encodeURIComponent(companyDetails.companyNumber)
      );
      companyForm.append("companyWebURL", companyDetails.companyWebURL);

      setLoading(true);
      let url = `${AUTH_SERVICE}api/onboarding/${onboardingID}/company/info`;
      postFormRequest(url, companyForm)
        .then((res) => {
          setLoading(false);
          if (res) {
            message.success("Company Details Added Successfully...!");
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getOnboardingDetails();
          } else {
            message.error(
              "Error while Add Company details, Please try after some time. "
            );
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      //update company details

      getCompanyDetails((updatedCompanyDetails) => {
        let url = `${AUTH_SERVICE}api/Company`;
        let companyUpdateBody = {
          ...updatedCompanyDetails,
          ...companyDetails,
        };
        putRequest(url, companyUpdateBody).then((res) => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (res) {
            message.success("Company Details Updated Successfully...!");
          } else {
            message.error("Error in Update");
          }
        });
      });
    }
  };

  const handleSaveSubscription = () => {
    let url = `${AUTH_SERVICE}api/company/modules`;
    if (!subscriptionData[0] && !subscriptionData[1]) {
      message.error("Please subscribe at least one Subscription");
    } else {
      let modules = [];
      if (subscriptionData[0]) {
        modules.push("contact center");
      }
      if (subscriptionData[1]) {
        modules.push("conference");
      }
      let onboardingID =
        window.location.pathname.toString().split("/")[1] || "";
      console.log("onboardingID ==>", onboardingID);

      let postBody = {
        id: onboardingDetails.regCompany.id,
        modules: modules,
      };
      setLoading(true);
      patchRequest(url, postBody)
        .then((res) => {
          setLoading(false);
          if (res) {
            message.success("Subscription Details Added Successfully...!");
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            message.error(
              "Error while Add Subscription details, Please try after some time. "
            );
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const getAdminDetails = (callback, onboardingDetails) => {
    let url = `${AUTH_SERVICE}Users/company/${onboardingDetails.regCompany.id}`;

    getRequest(url).then((res) => {
      console.log("Admin details ->", res);
      if (res) {
        res = res[0] ? res[0] : res;

        // add admin details to form fields
        form.setFieldsValue({
          ...res,
        });

        if (callback) {
          callback(res);
        }
      } else {
        message.error("Unable to get User details, Please try after some time");
      }
    });
  };

  const handleSaveAdmin = (adminDetails) => {
    getAdminDetails((updatedAdminDetails) => {
      let url = `${AUTH_SERVICE}api/onboarding/${onboardingDetails.id}/User`;
      let adminBody = {
        id: "",
        ...updatedAdminDetails,
        ...adminDetails,
        status: "offline",
        active: "true",
        role: "admin",
        baseUrl: BASE_URL || "agentqa.thebanknet.com",
        companyConfig: {
          id: onboardingDetails.regCompany.id,
        },
      };
      setLoading(true);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          dataType: "Json",
        },
        body: JSON.stringify(adminBody),
      };
      return fetch(url, requestOptions)
        .then(async (response) => {
          const startRes = await response.json();

          if (startRes.error) {
            setLoading(false);
            message.error(
              "Admin already exists, please try with different email id."
            );
          } else {
            //success
            setLoading(false);
            message.success("Admin Details Added Successfully...!");
            //completed
            handlePage("completed");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            "Error while Add Admin details, Please try after some time. "
          );
        });
    }, onboardingDetails);
  };

  const handleImageUpload = (propertyName, file) => {
    console.log("file -->", file, onboardingDetails);
    setCompanyLogo(file);

    // upload image to server
    if (onboardingDetails && onboardingDetails.regCompany && !file.status) {
      const companyForm = new FormData();
      companyForm.append("file", file);
      companyForm.append("companyId", onboardingDetails.regCompany.id);

      setCompanyLogo(false);
      let url = `${AUTH_SERVICE}api/company/logo`;
      postFormRequest(url, companyForm).then((logoRes) => {
        if (logoRes) {
          getCompanyDetails();
          message.success("Company Logo Updated Successfully...!");
        } else {
          message.error("error in logo update. ");
          setCompanyLogo("new");
        }
      });
    }
  };

  const CUSTOMER_DETAILS = () => (
    <Card hoverable>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Grid className="c-fields" item xs={12} sm={12} md={12} xl={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="company"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your company name",
                },
                {
                  pattern: new RegExp(/^[a-zA-Z\s,-]*$/),
                  message: "Please enter only alphabetic characters",
                },
              ]}
            >
              <Input placeholder="Please enter your company name" />
            </Form.Item>
          </Grid>

          <Grid className="c-fields" item xs={12} sm={12} md={12} xl={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="Company Email"
              name="companyEmail"
              placeholder="Please enter your company email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter your valid company email",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Please enter your company email"
              />
            </Form.Item>
          </Grid>
          <Grid className="c-fields" item xs={12} sm={12} md={12} xl={12}>
            <Form.Item
              name="companyWebURL"
              labelCol={{ span: 24 }}
              label="Company Website"
              rules={[
                {
                  required: true,
                  message: "Please enter website URL",
                },
                {
                  pattern: new RegExp(
                    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
                  ),
                  message: "Please enter valid website URL",
                },
              ]}
            >
              <Input placeholder="Please enter your website URL" />
            </Form.Item>
          </Grid>

          <Grid className="c-fields" item xs={12} sm={12} md={12} xl={12}>
            <Form.Item
              name="companyNumber"
              labelCol={{ span: 24 }}
              label="Company Contact Number"
              rules={[
                {
                  required: true,
                  message: "Please enter your company contact number",
                },
              ]}
              getValueFromEvent={(
                value,
                country,
                e,
                formattedValue // to add "+" sign on input field
              ) => formattedValue.replace(/[^0-9+]/g, "")}
            >
              <PhoneInput country={"us"} />
            </Form.Item>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <div className="company-logo-div">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Card hoverable>
                  <div className="upload-main-div">
                    <div className="upload-item">
                      <p>Company Logo</p>
                    </div>
                    <div className="upload-item">
                      {companyLogo ? (
                        <AntdUpload
                          currentImage={companyLogo || ""}
                          handleImage={handleImageUpload}
                          propertyName={"logo"}
                          handleUpdate={handleImageUpload}
                        />
                      ) : (
                        <div style={{ paddingTop: "15px" }}>{antIcon}</div>
                      )}
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Card>
  );

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  const CONTACT_CENTER_INFO = [
    {
      title: "Live Chat",
      description: `Live chat provides immediate service to customers through 
      their website. It helps to communicate using audio, video, and screen 
      share facility efficiently.`,
    },
    {
      title: "Canned Responses",
      description: `A canned response efficiently eliminates the time spent repeating and 
      rephrasing FAQ answers. Dialogview can define an unlimited number of 
      canned responses and can be accessed by all agents in general.`,
    },
    {
      title: "Document Repository",
      description: `Real-time conferencing allows agents and users to connect instantly by 
      utilizing audio or video calls. They can have calls in between the 
      conversation. If the agent wants to add a guest to the call, they can invite 
      them via SMS or Mail. A maximum of 6 guests can be added to the call.
      `,
    },
    {
      title: "Omnichannel Integration",
      description: `Omnichannel provides multi-channel customer support on popular
      messaging apps under one platform.A well-functioning omnichannel process flow, including streamlined storage 
      and delivery processes, guarantees that the company can execute its 
      commitments and promote productivity and long-termperformance.`,
    },
  ];

  const CONFERENCING_CENTER_INFO = [
    {
      title: "Scheduled Conferencing",
      description: `Scheduled conferencing allows an agent to schedule a meeting for guests 
      and the company’s members. There is no specified limitation for the 
      number of members to be added.`,
    },
    {
      title: "Screen Sharing",
      description: `Screenshare feature in dialogview helps to share a particular content or 
      the whole screen with a customer. In a real-time scenario, a client can fill 
      out a registration form through screen share with an agent.`,
    },
    {
      title: "Whiteboard",
      description: `Dialogview conference also provides a whiteboard 
      feature that is collaborative so that all the participants can work at 
      the same time.
      `,
    },
  ];

  const SUBSCRIPTION_DETAILS = () => (
    <Card className="f-subscription-card">
      <Grid container spacing={2} className="f-subscription-card">
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Card className="feature-card popping-card">
            <div className="f-title">
              <div className="f-icon f-item">
                {/* <CheckOutlined type="check-circle" className="feature-icon" /> */}
                <img src={CallCenterGif} height={60} width={60} />
              </div>
              <div className="f-desc f-item">
                <p className="feature-title">Contact Center</p>
              </div>
            </div>
            <p className="feature-description">
              {/* Description of the feature goes here. */}
            </p>
            <div className="feature-list scroll-content">
              {CONTACT_CENTER_INFO.map((item) => (
                <div>
                  <div className="f-container">
                    <div className="f-check-icon">
                      <CheckOutlined
                        type="check-circle"
                        className="feature-icon"
                      />
                    </div>
                    <div className="f-check-desc">
                      <span className="feature-info">{item.title}</span>
                    </div>
                  </div>
                  <div className="f-check-description">{item.description}</div>
                </div>
              ))}
            </div>

            <div className="f-button">
              <Button
                type="primary"
                style={
                  subscriptionData[0]
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "#1677ff" }
                }
                className="feature-button popping-card"
                onClick={() => {
                  setLoading1(true);
                  setTimeout(() => {
                    subscriptionData[0] = !subscriptionData[0];
                    if (subscriptionData[0]) {
                      setButtonText1("Subscribe ");
                    } else {
                      setButtonText1("Subscribe ");
                    }
                    setSubscriptionData(subscriptionData);
                    setLoading1(false);
                  }, 1000);
                }}
              >
                {subscriptionData[0] ? (
                  <div className="f-button-box">
                    <div className="f-button-title">Subscribed</div>
                    <div>{!loading1 && <FiCheckCircle size={20} />}</div>
                    <div>{loading1 && antIcon}</div>
                  </div>
                ) : (
                  <div className="f-button-box">
                    <div className="f-button-title">{buttonText1}</div>
                    <div>{loading1 && antIcon}</div>
                  </div>
                )}
              </Button>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Card className="feature-card popping-card">
            <div className="f-title">
              <div className="f-icon f-item">
                {/* <CheckOutlined type="check-circle" className="feature-icon" /> */}
                <img src={VideoCallGif} height={60} width={60} />
              </div>
              <div className="f-desc f-item">
                <p className="feature-title">Conference</p>
              </div>
            </div>
            <p className="feature-description">
              {/* Description of the feature goes here. */}
            </p>
            <div className="feature-list">
              {CONFERENCING_CENTER_INFO.map((item) => (
                <div>
                  <div className="f-container">
                    <div className="f-check-icon">
                      <CheckOutlined
                        type="check-circle"
                        className="feature-icon"
                      />
                    </div>
                    <div className="f-check-desc">
                      <span className="feature-info">{item.title}</span>
                    </div>
                  </div>
                  <div className="f-check-description">{item.description}</div>
                </div>
              ))}
            </div>

            <div className="f-button">
              <Button
                type="primary"
                style={
                  subscriptionData[1]
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "#1677ff" }
                }
                className="feature-button popping-card"
                onClick={() => {
                  setLoading2(true);
                  setTimeout(() => {
                    subscriptionData[1] = !subscriptionData[1];
                    if (subscriptionData[1]) {
                      setButtonText2("Subscribe ");
                    } else {
                      setButtonText2("Subscribe ");
                    }
                    setSubscriptionData(subscriptionData);
                    setLoading2(false);
                  }, 1000);
                }}
              >
                {subscriptionData[1] ? (
                  <div className="f-button-box">
                    <div className="f-button-title">Subscribed</div>
                    <div>{!loading2 && <FiCheckCircle size={20} />}</div>
                    <div>{loading2 && antIcon}</div>
                  </div>
                ) : (
                  <div className="f-button-box">
                    <div className="f-button-title">{buttonText2}</div>
                    <div>{loading2 && antIcon}</div>
                  </div>
                )}
              </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );

  const ADMIN_DETAILS = () => (
    <Card hoverable>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <Form.Item
            label={"First Name"}
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please enter First Name",
              },
              {
                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                message: "Please enter only alphabetic characters",
              },
            ]}
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
        </Grid>

        <Grid item xs={12} sm={12} md={4} xl={4}>
          <Form.Item
            label={"Last Name"}
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please enter Last Name",
              },
              {
                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                message: "Please enter only alphabetic characters",
              },
            ]}
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </Grid>

        <Grid item xs={12} sm={12} md={4} xl={4}>
          <Form.Item
            name="email"
            label={"Email"}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your valid email",
              },
            ]}
          >
            <Input
              placeholder="Enter Email ID"
              // disabled={action === "add" ? false : true}
            />
          </Form.Item>
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <Form.Item
            name="contactNumber"
            labelCol={{ span: 24 }}
            label="Contact Number"
            rules={[
              {
                required: true,
                message: "Please enter your company contact number",
              },
            ]}
            getValueFromEvent={(
              value,
              country,
              e,
              formattedValue // to add "+" sign on input field
            ) => formattedValue.replace(/[^0-9+]/g, "")}
          >
            <PhoneInput country={"us"} />
          </Form.Item>
        </Grid>
      </Grid>
    </Card>
  );

  const onCancel = () => {
    setConfirmModalVisible(false);
  };

  const onFinishConfirm = () => {
    // save admin and complete
    setConfirmModalVisible(false);
    handleSaveAdmin(confirmModalData);
  };

  const CONFIRMATION_MODAL = () => (
    <Modal
      visible={confirmModalVisible}
      title="Confirm Your Company Details"
      onCancel={onCancel}
      closable={false}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => onFinishConfirm()}>
          OK
        </Button>,
      ]}
      width={1000}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Row style={{ width: "100%" }}>
          <Col md={8} xl={8} sm={24} xs={24}>
            <div style={{ marginRight: "50px" }}>
              <h3>Company Details</h3>
              <p>
                <b>Name :</b> {confirmModalData.company}
              </p>
              <p>
                <b>Email :</b> {confirmModalData.companyEmail}
              </p>
              <p>
                <b>Contact No :</b> {confirmModalData.companyNumber}
              </p>
              <p>
                <b>Website :</b> {confirmModalData.companyWebURL}
              </p>
            </div>
          </Col>
          <Col md={8} xl={8} sm={24} xs={24}>
            <div style={{ marginRight: "50px", minWidth: "30%" }}>
              <h3>Subscription Details</h3>
              <p>
                <b>Contact Center : </b>{" "}
                {subscriptionData[0] ? "Subscribed" : "Not Subscribed"}
              </p>
              <p>
                <b>Conference :</b>{" "}
                {subscriptionData[1] ? "Subscribed" : "Not Subscribed"}
              </p>
            </div>
          </Col>
          <Col md={8} xl={8} sm={24} xs={24}>
            <div>
              <h3>Admin Details</h3>
              <p>
                <b>First Name :</b> {confirmModalData.firstName}
              </p>
              <p>
                <b>Last Name :</b> {confirmModalData.lastName}
              </p>
              <p>
                <b>Email : </b>
                {confirmModalData.email}
              </p>
              <p>
                <b>Contact No :</b> {confirmModalData.contactNumber}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );

  return (
    <Card
      hoverable
      title={
        <Row className="stepper-row">
          <Col md={4} lg={4} xs={24}>
            <div className="logo-container">
              <img
                src={DialogViewLogo}
                alt="Image"
                className="customer-form-logo"
              />
            </div>
          </Col>
          <Col md={18} lg={18} xs={24} style={{ padding: "20px" }}>
            {/* <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const labelProps = {};
                  if (isStepFailed(index)) {
                    labelProps.optional = (
                      <Typography variant="caption" color="error">
                        Alert message
                      </Typography>
                    );
                    labelProps.error = true;
                  }
                  return (
                    <Step key={label}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box> */}

            <Steps
              current={activeStep}
              items={[
                {
                  title: "Company Details",
                  status: activeStep > 0 ? "finish" : "process",
                  // icon: <ShopOutlined />,
                },
                {
                  title: "Subscription Details",
                  status: activeStep > 1 ? "finish" : "process",
                  // icon: <OrderedListOutlined />,
                },
                {
                  title: "Admin Details",
                  status: activeStep > 2 ? "finish" : "process",
                  // icon: <UserAddOutlined />,
                },
              ]}
            />
          </Col>
        </Row>
      }
    >
      <Row className="card-content" id="swipe-card-content">
        {loading && <Loading />}

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onFinish)}
          onFinishFailed={handleSubmit(onFinishFailed)}
          // autoComplete="off"
          style={{ width: "100%" }}
        >
          <Row>
            <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                style={{ width: "100% !important" }}
                onChangeIndex={handleStepChange}
                // enableMouseEvents
              >
                <div className="swipe-content" key={1}>
                  {CUSTOMER_DETAILS()}
                </div>
                <div className="swipe-content" key={2}>
                  {SUBSCRIPTION_DETAILS()}
                </div>
                <div className="swipe-content" key={3}>
                  {ADMIN_DETAILS()}
                </div>
              </SwipeableViews>
              {CONFIRMATION_MODAL()}
            </Box>
          </Row>
          <Row className="stepper-row-bottom">
            <MobileStepper
              className="mobile-stepper"
              steps={maxSteps}
              variant="dots"
              position="sticky"
              activeStep={activeStep}
              nextButton={
                <Button
                  className="nxt-prv-btn"
                  size="small"
                  onClick={() => handleNextOrBack("next")}
                  // disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <BiLeftArrowAlt size={20} style={{ paddingTop: "2px" }} />
                  ) : (
                    <BiRightArrowAlt size={20} style={{ paddingTop: "2px" }} />
                  )}
                </Button>
              }
              backButton={
                <Button
                  className="nxt-prv-btn"
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <BiRightArrowAlt size={20} style={{ paddingTop: "2px" }} />
                  ) : (
                    <BiLeftArrowAlt size={20} style={{ paddingTop: "2px" }} />
                  )}
                  Back
                </Button>
              }
            />
          </Row>

          {/* <Row>
            <Col>
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#458",
                  color: "#fff",
                  paddingBottom: "32%",
                }}
              >
                {"Save"}
              </Button>
            </Col>
          </Row> */}
        </Form>
      </Row>
    </Card>
  );
};

export default CustomerForm;
